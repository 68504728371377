import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {formTokko,updateFields} from '../redux/contactDucks'
import { connect } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { graphql, useStaticQuery } from 'gatsby';
import { getMessageWhatsapp} from '../helpers/helper.rendering'
import { useLocation } from "@reach/router";

const Contact = (props) => {
    const [text, setText] = useState("");
    const [disabled, setDisabled] = useState(true);
    const {data} = props
    const {dispatch} = props
    const {from} = props
    const {property} = props
    const {development} = props
    const {closeModal} = props
    const location = useLocation()

    const [datos, setDatos] = useState({
      nombre: "",
      apellido: "",
      cellphone: "",
      email: "",
      text: "",
    });

    const handleInputChange = (event) => {
        setDatos({
          ...datos,
          [event.target.name]: event.target.value,
        });
    };

    const sendForm = (event) => {
        event.preventDefault();
        if(from === 'development'){
            dispatch(formTokko(getUtm('Consulta Emprendimiento'),development.name,development.id,true))
        }
        if(from === 'property'){
            dispatch(formTokko(getUtm('Consulta Propiedad'),property.address,property.id))
        }
        if(from === 'contact'){
            dispatch(formTokko(getUtm('Consulta Web'),null,null))
        }
        document.getElementById("formContact").reset();

        return toast.success("¡Mensaje enviado correctamente!") 
    }

    useEffect(() => {
    const update = () => {
        dispatch(updateFields(datos));
    };
    update();

    // if(!realEstate?.keys?.captcha){
    //     setDisabled(false)
    // }
    // else{
    //     setDisabled(true)
    // }

    }, [datos]);

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              global_whatsapp
              keys {
                captcha
              }
          }
    }`)

    const checkPages = () => {
        const isHomePage = location.pathname.length === 1 && location.pathname.includes("/")
        const isContactPage = location.pathname.includes("contacto")
        const isNosotrosPage = location.pathname.includes("nosotros")
        const isDevsPage = location.pathname.length === 17 && location.pathname.includes("/emprendimientos/")

        return (isHomePage || isContactPage || isNosotrosPage || isDevsPage) ? true : false;
    }

    const getUtm = (customs = []) => {
        if(typeof window !== 'undefined'){
            var queryString = window.location.search
            var urlParams = new URLSearchParams(queryString)
            const tags = [customs]
            for (const [key, value] of urlParams) {
                if(key.includes("source")){tags.push("Origen: " + value)}
                if(key.includes("medium")){tags.push("Pieza: " + value)}
                if(key.includes("campaign")){tags.push("Campaña: " + value)}
            }
            return tags
        }
    }

    return(
        <form onSubmit={sendForm} id="formContact" class="row content-form ajax-form">
            <div class="col-lg-6 mb-lg-4">
                <input type="text" name="nombre" onChange={handleInputChange} placeholder="Nombre*" required />
            </div>
            <div class="col-lg-6 mb-lg-4">
                <input type="text" name="apellido" onChange={handleInputChange} placeholder="Apellido"  />
            </div>
            <div class="col-lg-6 mb-lg-4">
                <input type="email" name="email" onChange={handleInputChange} placeholder="Email*" required />
            </div>
            <div class="col-lg-6 mb-lg-4">
                <input type="text" name="cellphone" onChange={handleInputChange} placeholder="Teléfono*" required />
            </div>
            <div class="col-lg-12 mb-lg-1">
                <textarea name="text" cols="30" rows="7" onChange={handleInputChange} placeholder="Mensaje"></textarea>
            </div>
            <div class="col-lg-12 d-flex flex-column justify-content-center align-items-center">
                <p className="align-self-end text-grey">*Campos obligatorios.</p>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    { realEstate?.keys?.captcha
                        ? <ReCAPTCHA
                            sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                            onChange ={() => setDisabled(false)} /> 
                        :  '' }
                        {console.log("es " + closeModal)}
                    <input type="submit"
                        data-bs-dismiss="offcanvas" aria-label="Close"
                        disabled={disabled || ((datos.name === '' || datos.phone === '' || datos.email === '') && closeModal)} 
                        value={ !checkPages() ? "CONTACTAR POR FORMULARIO" : "ENVIAR" } 
                        class={"btn btn-red submit mt-4 mb-3 w-100 "+ (!closeModal && 'd-none')} id="submit-info" />
                    <input type="submit"
                        onClick={() => sendForm()}
                        disabled={disabled} 
                        value={ !checkPages() ? "CONTACTAR POR FORMULARIO" : "ENVIAR" } 
                        class={"btn btn-red submit mt-4 mb-3 w-100 "+(closeModal && 'd-none')} id="submit-info" />
                    { !checkPages() ?
                        <>        
                            <a href={
                                "https://web.whatsapp.com/send?text=" +
                                getMessageWhatsapp(development, property, location?.href) +
                                "&phone=+54" +
                                realEstate.global_whatsapp.replaceAll("-", "")}
                                target="_blank"
                                class={"btn btn-green d-none d-lg-flex align-items-lg-center w-100"}>
                                    <span className="d-flex align-items-center mx-auto">
                                        <span className="icon-whatsapp me-2"></span>
                                        CONTACTAR POR WHATSAPP
                                    </span>
                            </a>
                            <a href={
                                "https://api.whatsapp.com/send?text=" +
                                getMessageWhatsapp(development, property, location?.href) +
                                "&phone=+54" +
                                realEstate.global_whatsapp.replaceAll("-", "")}
                                target="_blank"
                                class={"btn btn-green d-flex align-items-center d-lg-none"}>
                                    <span className="icon-whatsapp me-2"></span>
                                    CONTACTAR POR WHATSAPP
                            </a>
                        </> : '' }
                </div>
            </div>
        </form>
    )
}
export default connect(state => ({
    data: state.contact.data,
    property:state.properties.property,
    development:state.developments.development
}),null)(Contact);
