import React from 'react';
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from 'gatsby';
import Breadcrumbs from "./breadcrumbs";
import Contact from './Contact';
import videoRecepcionista from "../videos/recepcionista.mp4";
import imgRecepcionista from "../videos/recepcionista_mobile.png";

const ContactWrapper = () => {

    const location = useLocation()
    const isContactPage = location.pathname.includes("contacto")
    const isNosotrosPage = location.pathname.includes("nosotros")
    const { realEstate } = useStaticQuery(
    graphql`
      query {
          realEstate {
              name
              global_email
              global_phone
              keys {
                captcha
              }
          }
    }`)

    const topContent = () => {
        if(isContactPage) {
            const breadcrumb = <Breadcrumbs props={[
                { name: realEstate.name, route: "/", location: "" },
                { name: "Contacto", route:   "", location: ""},
            ]}/>
            return breadcrumb;
        } else {
            return <p className="headline">Contactanos</p>
        }
    }

    const textContent = () => {
        if(isContactPage) {
            return <div className="row position-relative">
                { contactVidImg() }
                <div className="col-6 ms-auto ms-lg-0 me-auto">
                    <h2 className="title">Estamos para ayudarte</h2>
                    <p className="text mb-lg-5 mb-3">Dejanos tus datos y te contactaremos lo antes posible.</p>
                </div>
            </div>
        } else {
            return <>
                <h2 className="title">Estamos para ayudarte</h2>
                <p className="text mb-5">Dejanos tus datos y te contactaremos lo antes posible.</p>
            </>
        }
    }

    const getSize = () => (typeof window !== "undefined") ? (screen?.width < 992) ? false : true :''

    const contactVidImg = () => {
        return <div className="col-12 col-lg-2 offset-lg-1 d-flex justify-content-center justify-content-lg-end">
                    {getSize() && <div className="video-wrap">
                        <video id="video-contacto" loop muted autoPlay>
                            <source src={videoRecepcionista} type="video/mp4"/>
                            Tu navegador no soporta el video HTML.
                        </video>
                    </div>}
                    <img src={imgRecepcionista} alt="Contacto" className="img-contacto"/>
                </div>
    }

    const checkLocation = () => {
        if(location?.pathname?.toLowerCase().includes("propiedad")){
            return 'property'
        }
        if(location?.pathname?.toLowerCase().includes("emprendimiento")){
            return 'development'
        }
        else{
            return 'contact'
        }
    }

    return (
        <section className={`contact-section ${isContactPage ? 'contact-page' : ''} 
            ${isNosotrosPage ? 'nosotros-page' : ''}`}>
            <div className={`container-fluid ${isContactPage ? 'contact-container' : ''}`}>
                <div className="row">
                    <div className="col-12 top-content">
                        { topContent() }
                    </div>
                    <div className="col-12 text-content">
                        { textContent() }   
                    </div>
                    <div className={`col-12 form-content ${isContactPage ? 'contact-form-wrapper' : 'contact-form'}`}>
                        <Contact from={checkLocation()}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactWrapper;